.explore-react * {
  font-family: "Nunito", sans-serif;
  color: rgba(51, 51, 51, 1);
}
body {
  padding: 0px !important;
}

html #twyg-ad-container * {
  font-family: "Nunito", sans-serif !important;
}

.explore-react button:focus {
  outline: none !important;
}

.explore-react #btn {
  display: flex;
  align-items: center;
  justify-content: center;
}

.explore-react .swiper {
  /* padding: 0 8px; */
  overflow: hidden !important;
}

#explore-swiper-button {
  height: calc(100% + 2px);
}

.swiper-slide img {
  transition: none !important;
}

.hidden {
  display: none;
}

.custom-bg {
  background: linear-gradient(
    298.22deg,
    #f06ba8 4.04%,
    #beb296 32.98%,
    #a6d38d 63.22%
  );
}

.custom-color {
  color: rgba(8, 79, 75, 1);
}

.text-dim-gray {
  color: rgba(128, 128, 128, 1);
}

.text-go-green {
  color: rgba(0, 163, 117, 1);
}

.custom-border-dark-green {
  border-color: rgba(8, 79, 75, 1);
}

.custom-border-mid-gray {
  border-color: rgba(204, 204, 204, 1);
}

.fit-content {
  width: fit-content;
}

.text-dark-green {
  color: rgba(8, 79, 75, 1);
}

.bg-custom {
  background: rgba(8, 79, 75, 1);
}

.bg-mid-gray {
  background: rgba(230, 231, 235, 1);
}

.bg-anti-flash {
  background: rgba(230, 231, 235, 1);
}

.border-none {
  border-width: 0px !important;
}

.jet-black {
  color: #333333;
}

.w-fit-content {
  width: fit-content;
}

.shadow-popup {
  box-shadow: 0px 8px 12px 0px rgba(128, 128, 128, 0.15);
}

.scroll-custom::-webkit-scrollbar {
  /* Customize the scrollbar width */
  width: 6px;
  height: 6px;
  margin: 10px;
}

.scroll-custom::-webkit-scrollbar-track {
  /* Customize the scrollbar track */
  width: 6px;
  height: 6px;
  margin: 10px;
}

.scroll-custom::-webkit-scrollbar-thumb {
  /* Customize the scrollbar thumb */
  padding: 8px 0px;
  background: #c6ceda;
  border-radius: 20px;
  margin: 10px;
}

.max-w-1-4 {
  max-width: 25%;
}

.min-w-1-4 {
  min-width: 25%;
}

.chevron-icon {
  fill: #084f4b;
}

.mode-dark {
  fill: #fff;
}

.chevron-icon:hover {
  fill: rgba(0, 163, 117, 1);
}

.arrow-icon {
  stroke: #084f4b;
}

.arrow-icon:hover {
  stroke: rgba(0, 163, 117, 1);
}

/* .pdf-document {
  width: fit-content;
  margin: 0 auto;
}

.pdf-document .textLayer,
.pdf-document .annotationLayer {
  display: none;
}

.pdf-document .page-canvas {
  display: flex;
  justify-content: center;
}

.pdf-document .page-canva canvas {
  margin-left: auto !important;
  margin-right: auto !important;
}

.pdf-draft .textLayer,
.pdf-draft .annotationLayer {
  display: none;
}

.pdf-draft .page-canvas {
  display: flex;
  justify-content: center;
}

.pdf-draft .page-canva canvas {
  margin-left: auto !important;
  margin-right: auto !important;
}

.pdf-draft canvas {
  min-width: 56px !important;
  max-width: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important;
} */

.pdf-container .react-pdf__Page__canvas {
  width: 100% !important;
  height: 100% !important;
}

.pdf-document .textLayer,
.pdf-document .annotationLayer {
  display: none;
}

.pdf-document .page-canvas {
  display: flex;
  justify-content: center;
}

.pdf-document .page-canva canvas {
  margin-left: auto !important;
  margin-right: auto !important;
}

.pdf-draft .textLayer,
.pdf-draft .annotationLayer {
  display: none;
}

.pdf-draft .page-canvas {
  display: flex;
  justify-content: center;
}

.pdf-draft .page-canva canvas {
  margin-left: auto !important;
  margin-right: auto !important;
}

.pdf-draft canvas {
  min-width: 56px !important;
  max-width: 56px !important;
  min-height: 56px !important;
  max-height: 56px !important;
}

@media (max-width: 768px) {
  .dropzone-twygs {
    height: auto;
    padding: 16px 24px;
  }

  #chart-container-performance > div {
    height: 100px !important;
  }

  .react-pdf__Page__canvas {
    width: 100% !important;
  }
}

.twyg-ad-container {
  z-index: 10;
}

.content-ad p {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-inline-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.content-ad-image {
  object-fit: cover;
}

.content-ad-info-container {
  padding: 8px;
  display: flex;
  align-items: center;
  min-height: 88px;
}

#twyg-ad-container {
  z-index: 2;
  font-family: "Nunito", sans-serif !important;
}

.ad-logo {
  min-width: 44px;
  min-height: 44px;
  max-width: 44px;
  max-height: 44px;
  object-fit: cover;
  border-radius: 8px;
  border: 1px solid #e6e7eb;
  margin-right: 12px;
}

.ad-title {
  font-size: 16px;
  font-weight: 800;
  max-width: 100%;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; */
  white-space: normal;
  word-wrap: break-word;
}

.ad-description {
  font-size: 12px;
  font-weight: 400;
  max-width: 100%;
  /* overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 1; */
  white-space: normal;
  word-wrap: break-word;
}

.learn-more {
  padding: 8px 14px;
  border-radius: 40px;
  background: #333;
  border: 1px solid #333;
  font-size: 12px;
  font-weight: 900;
  min-width: 100px;
  color: white;
  text-wrap: nowrap;
  text-align: center;
}

.related-twyg-item .related-category {
  padding: 8px 12px;
  border-radius: 40px;
  background: #e6e7eb;
  font-size: 12px;
  font-weight: 400;
  height: 32px;
}

.related-twyg-item {
  transition: all 0.3s ease;
  cursor: pointer;
}

.anchor-bottom .ad-title {
  font-size: 20px !important;
  margin-bottom: 10px;
}

.anchor-bottom .ad-description {
  font-size: 14px !important;
}

/* .page:nth-child(odd) {
  left: 0 !important;
} */

@media screen and (max-width: 576px) {
  .content-ad p {
    -webkit-line-clamp: 2;
  }

  .twyg-ad-container-display-ad-top {
    height: auto !important;
  }

  #twyg-btn-skip-ad {
    bottom: 15px !important;
  }

  .content-ad-info-container {
    padding: 8px !important;
    min-height: unset;
  }

  .anchor-bottom .ad-title,
  .ad-title {
    font-size: 14px !important;
    margin-bottom: 0px !important;
  }

  .anchor-bottom .ad-description,
  .ad-description {
    font-size: 10px !important;
  }
  /* .explore-react .swiper {
    padding: 0 25px;
  } */

  #pdfSkeleton {
    height: 200px !important;
  }
  .ad-logo {
    margin-right: 8px;
  }

  .learn-more {
    font-size: 10px !important;
    padding: 4px 4px !important;
    min-width: 80px !important;
    margin-top: -8px !important;
  }

  .full-page-twyg .learn-more {
    font-size: 14px !important;
    padding: 8px !important;
  }
  /*
  .twyg-ad-container-display-ad-top p {
    max-width: 100%;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 1;
    text-wrap: nowrap !important;
    overflow: hidden;
    text-overflow: ellipsis;
  } */

  /* .page:nth-child(odd) {
    left: unset !important;
  } */

  /* .react-pdf__Page {
    left: 0 !important;
    height: 100% !important;
    width: 100% !important;
    --scale-factor: 0 !important;
    background-color: white;
    position: unset;
    min-width: unset;
    min-height: unset;
  } */

  .zoom-pan-wrapper {
    height: 1000px;
  }
  .page-cover,
  .page-content {
    /*width: 100% !important;*/
    /*left: 0px !important;*/
  }
  /* .stf__hardShadow,
  .stf__hardInnerShadow,
  .stf__outerShadow .stf__innerShadow {
    left: 0 !important;
  } */
  .custom-left-important {
    left: 0 !important;
  }
}

#content {
  max-width: 100%;
  width: 100%;
  position: relative;
  /* min-height: 500px; */
  margin: 0 auto;
  overflow: hidden;
  display: flex;
  align-items: center;
  justify-content: center;
}

#pdfSkeleton {
  width: 100%;
  height: 600px;
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) 0%,
    rgba(0, 0, 0, 0.2) 50%,
    rgba(0, 0, 0, 0) 100%
  );
  background-size: 200% 100%;
  animation: skeletonAnimation 1.5s infinite;
}

@keyframes skeletonAnimation {
  0% {
    background-position: 100% 0;
  }

  100% {
    background-position: -100% 0;
  }
}

.swiper-wrapper {
  display: flex !important;
  align-items: center !important;
}

/* DateRangePickerComponent.module.css */

/* Container datepicker */

.datePicker .react-datepicker {
  border-radius: 24px;
  border: 1px solid #f2f3f7;
  box-shadow: 0px 8px 12px 0px rgba(128, 128, 128, 0.15);
  padding: 24px;
  width: 370px;
  height: 439px;
  line-height: 30px;
}

.datePicker .react-datepicker-wrapper {
  width: 100%;
}

.datePicker .react-datepicker .react-datepicker__navigation--previous {
  top: 24px;
  left: 24px;
}

.datePicker .react-datepicker .react-datepicker__navigation--next {
  top: 24px;
  right: 24px;
}

.datePicker .react-datepicker .react-datepicker__month-container {
  width: 100%;
}

.datePicker .react-datepicker .react-datepicker__month {
  margin-top: 50px !important;
  height: 302px;
  padding: 24px 0px;
}

/* Header */

.datePicker .react-datepicker__month-container .react-datepicker__header {
  background-color: #ffff;
  border: none;
}

.datePicker .react-datepicker__day-names {
  border: none;
  margin-top: 58px;
}

.datePicker .react-datepicker__day-name {
  width: 46px;
  height: 33px;
  margin: 0;
  color: #0d0d0d;
  font-weight: 900;
}

.datePicker .react-datepicker-popper {
  width: unset;
  z-index: 10;
}
.datePicker .react-datepicker-popper .react-datepicker__day {
  border-radius: 0;
  width: 46px !important;
  height: 33px;
  margin: 0;
}

/* Default range */
.datePicker .react-datepicker-popper .react-datepicker__day--in-range {
  background-color: #dceed3;
}

.datePicker .react-datepicker__month {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 230px;
}

/* On select range */
.datePicker
  .react-datepicker-popper
  .react-datepicker__day--in-selecting-range {
  background-color: #dceed3;
}

.datePicker
  .react-datepicker-popper
  .react-datepicker__day--selecting-range-end {
  background-color: white;
}
.datePicker
  .react-datepicker-popper
  .react-datepicker__day--selecting-range-start {
  background-color: white;
}

.datePicker
  .react-datepicker-popper
  .react-datepicker__day--selecting-range-end
  .date-custom,
.datePicker
  .react-datepicker-popper
  .react-datepicker__day--selecting-range-start
  .date-custom {
  background-color: #084f4b;
  border-radius: 50%;
  color: white;
  width: 32px;
  height: 32px;
  margin: auto;
}

/* Selected */
.datePicker .react-datepicker-popper .react-datepicker__day--range-start {
  background-color: white;
  border-radius: 50px 0 0 50px;
  color: white;
  width: 32px;
  /* margin-left: 5px; */
}

.datePicker .react-datepicker-popper .react-datepicker__day--range-end {
  background-color: white;
  border-radius: 0px 50px 50px 0px;
  color: white;
}

.datePicker
  .react-datepicker-popper
  .react-datepicker__day--range-start
  .date-custom,
.datePicker
  .react-datepicker-popper
  .react-datepicker__day--range-end
  .date-custom {
  background-color: #084f4b;
  border-radius: 50%;
  width: 32px;
  height: 32px;
  color: #fff;
}

.datePicker .react-datepicker-popper .date-custom {
  /* margin: auto;
  margin-right: 0px; */
  margin: 0 auto;
  font-weight: 600;
  height: 33px;
}

/* Hover */
.datePicker .react-datepicker-popper .react-datepicker__day:hover {
  background-color: transparent;
}
.datePicker .react-datepicker-popper .react-datepicker__day .date-custom:hover {
  background-color: #084f4b;
  border-radius: 50px;
  color: #fff;
  width: 32px;
  height: 32px;
  margin: auto;
}

.datePicker
  .react-datepicker__month-container
  .react-datepicker__day--selected {
  background-color: #084f4b;
  border-radius: 50px;
  color: #fff !important;
  width: 32px !important;
  height: 32px !important;
  margin: 0 7px;
}

.datePicker
  .react-datepicker__month-container
  .react-datepicker__day--selected
  p {
  color: #fff !important;
}

.datePicker .react-datepicker__month .react-datepicker__day--disabled {
  color: #cccccc !important;
  cursor: not-allowed;
}
.datePicker .react-datepicker__month .react-datepicker__day--disabled p {
  color: #cccccc !important;
}
.datePicker .react-datepicker__month .react-datepicker__day--disabled:hover {
  background-color: transparent;
  cursor: not-allowed;
}
.datePicker
  .react-datepicker__month
  .react-datepicker__day--disabled
  .date-custom:hover {
  background-color: transparent;
  color: #cccccc;
  cursor: not-allowed;
}

.datePicker .react-datepicker__navigation-icon--next,
.datePicker .react-datepicker__navigation-icon--previous {
  display: none;
}

.datePicker .react-datepicker__navigation {
  border-radius: 8px;
  width: 40px;
  height: 40px;
  border: 1px solid #cccccc;
}

.datePicker .react-datepicker__month-container .react-datepicker__header {
  background-color: #fff;
  border-bottom: 1px solid #e6e7eb;
  height: 64px;
  text-align: center;
  color: #084f4b;
  font-weight: 900;
  font-size: 16px;
  position: unset;
}

.datePicker .react-datepicker__current-month {
  color: #084f4b;
  font-weight: 900;
}

.creator-name p {
  transition: 0.15s color ease;
}

/* Point to the last elm */
.creator-name p:hover {
  color: #a6d38d;
}

input:placeholder-shown {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.adsbygoogle-noablate,
ins.adsbygoogle[data-ad-status="unfilled"] {
  display: none !important;
}

div::-webkit-scrollbar-thumb {
  background: #fff;
}

.scrollable-div:hover::-webkit-scrollbar-thumb {
  background: #ddd;
}

/* flip pdf */
/* .fullscreen {
  width: 100vw;
  height: 100vh;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
} */

.dropzone-twygs {
  height: 360px;
  /* dashed border */
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='12' ry='12' stroke='%23A6D38DFF' strokeWidth='1' stroke-dasharray='6' stroke-dashoffset='6' strokeLinecap='square'/%3e%3c/svg%3e");
}

.full-screenable-node {
  background: #000;
}

.react-transform-wrapper,
.react-transform-component {
  width: 100% !important;
}

.text-align-container * {
  text-align: center;
}

.size-text-desktop {
  width: 100%;
}

.size-text-desktop .ad-title {
  font-size: 38px !important;
}

.size-text-desktop .ad-description {
  font-size: 22px !important;
  color: #555555 !important;
}

.learn-more-style-pdf .learn-more {
  border-radius: 6px !important;
  font-size: 16px;
}

.size-text-mobile {
  width: 100%;
}

.size-text-mobile .ad-title {
  font-size: 22px !important;
}

.size-text-mobile .ad-description {
  font-size: 18px !important;
}

.learn-more-style-pdf .learn-more {
  border-radius: 6px !important;
  font-size: 16px !important;
}

.mobile-ad-info-container {
  display: flex !important;
  padding: 30px;
}

.desktop-ad-info-container {
  display: flex !important;
  padding: 30px;
}

.dekstop-mini-width .ad-title {
  font-size: 12px !important;
}

.dekstop-mini-width .ad-description {
  font-size: 10px !important;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.dekstop-mini-width {
  display: flex;
}

.dekstop-mini-width #learn-more-style {
  font-size: 12px !important;
  width: fit-content;
}

/* .stf__block {
  position: relative;
}

.page {
  position: absolute;
  left: 0 !important;
} */
